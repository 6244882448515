import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Linking, Dimensions, TouchableOpacity } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const screenWidth = Dimensions.get('window').width

export default function BlackEntrepreneursGrants () {
  return (
    <>
      <Header/>

      {/* Title and Introduction */}
      <View style={styles.headerSection}>
        <Text style={styles.title}>How Black Entrepreneurs Can Access Grants to Build Thriving Businesses</Text>
      </View>

      <ScrollView style={styles.container}
      showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
      showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
      >
        <View style={styles.sectionContainer}>
          <View style={styles.section}>
            <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter11.webp")}/>
            <Text style={styles.introText}>
            Black entrepreneurs in Canada face unique challenges when it comes to accessing the financial resources needed to start, grow, and sustain successful businesses. However, several grant programs and funding opportunities are specifically designed to support Black-owned businesses and provide the capital, mentorship, and resources necessary for long-term success. These initiatives are available through government programs, private institutions, and community-based organizations, all working to empower Black entrepreneurs to overcome barriers and thrive in today’s economy.
            </Text>
          </View>

          {/* Black Entrepreneurship Program (BEP) */}
          <View style={styles.section}>
            <Text style={styles.subtitle}>Black Entrepreneurship Program (BEP)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter31.avif")}/>
            <Text style={styles.text}>
            The Black Entrepreneurship Program (BEP) is one of the most important funding initiatives available to Black business owners in Canada. Launched by the federal government in collaboration with Black-led business organizations, the BEP includes several funding streams designed to foster entrepreneurship within the Black community.
            </Text>
            <Text style={styles.text}>
            One of the key elements is the National Ecosystem Fund, which helps Black entrepreneurs access business mentorship, skills development, and financial support. This fund works to strengthen networks that Black entrepreneurs can tap into for ongoing support.
            </Text>
            <Text style={styles.text}>
            In addition to the National Ecosystem Fund, the BEP offers the Black Entrepreneurship Loan Fund, providing loans up to $250,000 to Black entrepreneurs and Black-owned businesses. This loan fund aims to remove the barriers often encountered in mainstream lending markets, making it easier for Black entrepreneurs to access the capital they need to grow their businesses.
            </Text>
          </View>

          {/* Futurpreneur Canada – Black Entrepreneur Startup Program */}
          <View style={styles.section}>
              <Text style={styles.subtitle}>Futurpreneur Canada – Black Entrepreneur Startup Program</Text>
              <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter1.avif")}/>
              <Text style={styles.text}>
              For younger Black entrepreneurs between the ages of 18 and 39, the Black Entrepreneur Startup Program offered by Futurpreneur Canada is a valuable resource. This program provides up to $60,000 in startup loans, as well as access to two years of business mentorship.
              </Text>
              <Text style={styles.text}>
              The Black Entrepreneur Startup Program aims to address the unique challenges faced by Black entrepreneurs, such as limited access to capital and lack of mentorship. By offering both financial and advisory support, the program helps young Black entrepreneurs build their businesses with confidence and pursue sustainable growth.
              </Text>
          </View>

          {/* Regional and Community-Based Grants */}
          <View style={styles.section}>
              <Text style={styles.subtitle}>Regional and Community-Based Grants</Text>
              <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnterCoop21.avif")}/>
              <Text style={styles.text}>
              Several regional and community-based grants are also available to Black entrepreneurs across Canada. For instance, FACE (Federation of African Canadian Economics) partners with financial institutions to provide loans, grants, and business development services. FACE’s mission is to empower Black entrepreneurs with access to tailored financial solutions, mentorship, and business training.
              </Text>
              <Text style={styles.text}>
              Another notable organization is the Black Business and Professional Association (BBPA), which offers various grants, scholarships, and financial literacy programs to foster the success of Black entrepreneurs. The BBPA also hosts the annual Harry Jerome Awards, which recognizes Black excellence in business and offers networking opportunities for entrepreneurs across the country.
              </Text>
          </View>

          {/* Corporate and Private Sector Grants */}
          <View style={styles.section}>
          <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/bleckEnterCoop1.webp")}/>
              <Text style={styles.text}>
              Several corporate programs in Canada have recognized the importance of supporting Black-owned businesses. The TD Ready Commitment program and RBC Future Launch initiative are two examples of corporate-led efforts to address economic disparities and offer funding to Black-owned businesses.
              </Text>
              <Text style={styles.text}>
              These programs provide financial grants, business advisory services, networking opportunities, and leadership training. Additionally, private foundations like the Canadian Women’s Foundation and Women’s Entrepreneurship Knowledge Hub (WEKH) focus on supporting Black women entrepreneurs.
              </Text>
          </View>

          {/* Navigating the Grant Application Process */}
          <View style={styles.section}>
              <Text style={styles.subtitle}>Navigating the Grant Application Process</Text>
              <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnterRegion.webp")}/>
              <Text style={styles.text}>
              Successfully accessing grants and funding requires a clear understanding of the application process. Black entrepreneurs should start by researching the available grants and ensuring they meet eligibility criteria.
              </Text>
              <Text style={styles.text}>
              The next step is crafting a strong business plan. Many grant applications require this document, which should outline your business goals, target market, financial projections, and how the funds will be used. Many grant programs also offer mentorship to guide applicants through the process.
              </Text>
          </View>

          {/* Leveraging Community and Business Networks */}
          <View style={styles.section}>
              <Text style={styles.subtitle}>Leveraging Community and Business Networks</Text>
              <Image style={styles.image} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter41.webp")}/>
              <Text style={styles.text}>
              Building and leveraging networks is key to increasing the chances of securing funding. Organizations like the Canadian Black Chamber of Commerce and the Black Business Initiative (BBI) offer networking opportunities, mentorship programs, and resources to help Black entrepreneurs connect with investors, partners, and potential customers.
              </Text>
              <Text style={styles.text}>
              Attending events, workshops, and seminars hosted by these organizations can help entrepreneurs stay informed about upcoming funding opportunities, business trends, and best practices.
              </Text>
          </View>

          {/* Conclusion Section */}
          <View style={styles.section}>
              <Text style={styles.subtitle}>Conclusion</Text>
              <Text style={styles.text}>
              Black entrepreneurs in Canada have access to a variety of grants and funding programs designed to help them build thriving businesses. From national programs like the Black Entrepreneurship Program to regional and community-based initiatives, Black business owners can find the financial support and mentorship they need to succeed.
              </Text>
              <Text style={styles.text}>
              By understanding the grant application process, building strong business plans, and tapping into networks, Black entrepreneurs can overcome challenges and access the resources needed for growth and long-term success.
              </Text>
          </View>

          {/* Link to FindGrant.ca */}
          {/* <View style={styles.section}>
              <Text style={styles.text}>
              For more information on grants and funding opportunities for Black entrepreneurs, visit the
                <Text 
                    style={styles.link} 
                    onPress={() => Linking.openURL('https://findgrant.ca/BlackEntrepreneurshipGrants')}
                >
                    &nbsp;FindGrant.ca Black and BIPOC Business Grants page
                </Text>.
              </Text>
          </View> */}

          <View style={styles.section}>
            {/* Call to Action */}
            <View style={[styles.cta, ]}>
                <Text style={styles.ctaText}>
                For more information on grants and funding opportunities for Black entrepreneurs, visit the {' '}
                    <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/BlackEntrepreneurshipGrants')}>
                    <Text style={styles.ctaLink}>FindGrant.ca</Text>
                    </TouchableOpacity>'s Black and BIPOC Business Grants page.
                </Text>
            </View>
          </View>
        </View>
        <Footer/>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f9f9f9',
    // padding: 16,
  },
  headerSection: {
    paddingTop: 10,
  },

  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  link: {
    color: '#2980b9',
    // textDecorationLine: 'underline',
  },
  headerImage: {
    width: '100%',
    height: 200,
    borderRadius: 8,
    marginBottom: 16,
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
