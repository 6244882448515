import React, { useRef, useState } from "react";
import { StyleSheet, Text, View, Animated, Easing, Dimensions, Pressable } from "react-native";
import { Image } from "react-native-web";
import ScrollRightButton from "./SearchProgram/ScrollRightButton";
import { useRoute } from "@react-navigation/native";
import ListGrants from "../components/ListGrants";

const screenWidth = Dimensions.get('window').width

const GrantOptionsIcons = ({ activeIcon, setActiveIcon}) => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const scrollX = useRef(new Animated.Value(0)).current;

  const route = useRoute(); // Hook to access parameters
  const { selectedValues = {} } = route.params || {}; // Selected data from SearchBar
  const [showPrograms, setShowPrograms] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [activeCard, setActiveCard] = useState(null);
  const [selectedProgramType, setSelectedProgramType] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    location: selectedValues.location || "",
    community: selectedValues.community || "",
    industry: selectedValues.industry || "",
    grants: selectedValues.grants || "",
  });

  const [filteredGrants, setFilteredGrants] = useState([]);


  const allIcons = [
    {
      id: 1,
      icon: require("../../assets/searchPage/funding.svg"),
      text: "Funding/Grant",
    },
    {
      id: 2,
      icon: require("../../assets/searchPage/report.svg"),
      text: "Tax Credit and Incentive",
    },
    {
      id: 3,
      icon: require("../../assets/searchPage/files-and-folders.svg"),
      text: "Loan and Financing",
    },
    {
      id: 4,
      icon: require("../../assets/searchPage/costumer.svg"),
      text: "Expert Advice",
    },
    {
      id: 5,
      icon: require("../../assets/searchPage/cooperation.svg"),
      text: "Partnering and Collaboration",
    },
    {
      id: 6,
      icon: require("../../assets/searchPage/clipboard.svg"),
      text: "Insurance",
    },
    {
      id: 7,
      icon: require("../../assets/searchPage/facility-management.svg"),
      text: "Facility",
    },
  ];


  const getVisibleIcons = () => {
    let visibleIcons = [];
    for (let i = 0; i < allIcons.length; i++) {
      visibleIcons.push(allIcons[i]);
    }
    return visibleIcons;
  };


  const fetchFilteredGrants = async (filters) => {
    try {
      const formData = new URLSearchParams();

      if (filters.location) formData.append("location", filters.location);
      if (filters.community) formData.append("ethnicity", filters.community);
      if (filters.industry) formData.append("industry", filters.industry);
      if (filters.grants) formData.append("program_target", filters.grants);

      console.log('Sending data: ', formData);

      const response = await fetch(
        "https://api.findgrant.ca/webpage/form-submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      const data = await response.json();
      console.log('Sending data: ', data.program_result);

      setFilteredGrants(data.program_result || []);
      setShowPrograms(true);

      // await AsyncStorage.setItem('results', JSON.stringify(data.program_result));
    } catch (error) {
      console.error("Error fetching filtered grants:", error);
    }
  };






  //This can be used for responsiveness

  // const scrollRight = () => {
  //   Animated.timing(scrollX, {
  //     toValue: -117, // Move left by one icon width
  //     duration: 300, // Smooth transition
  //     easing: Easing.ease,
  //     useNativeDriver: true, // Enable hardware acceleration
  //   }).start(() => {
  //     // Update the current index after animation
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % allIcons.length);
  //     scrollX.setValue(0); // Reset the scroll position
  //   });
  // };

  // return (
  //   <View style={styles.wrapper}>
  //     <View style={styles.scrollWrapper}>
  //       {/* <Animated.View
  //         style={[
  //           styles.iconsContainer,
  //           {
  //             transform: [
  //               {
  //                 translateX: scrollX.interpolate({
  //                   inputRange: [-117, 0],
  //                   outputRange: [-117, 0],
  //                 }),
  //               },
  //             ],
  //           },
  //         ]}
  //       >
  //         {getVisibleIcons().map((item, index) => (
  //           <View key={index} style={styles.eachIcon}>
  //             <Image style={styles.icons} source={item.icon} />
  //             <Text style={styles.text}>{item.text}</Text>
  //           </View>
  //         ))}

  //       </Animated.View> */}

  //     </View>
  //     {getVisibleIcons().map((item, index) => (
  //       <View key={index} style={styles.eachIcon}>
  //         <Image style={styles.icons} source={item.icon} />
  //         <Text style={styles.text}>{item.text}</Text>
  //       </View>
  //     ))}
      // {/* <View style={styles.buttonWrapper}>
      //   <View style={styles.gradientEffect}></View>
      //   <ScrollRightButton onPress={scrollRight} />
      // </View> */}
  //   </View>
  // );

  const handlePress = (iconID) => {
    setActiveIcon(iconID)
  }

  // const handleProgramClick = async (id, programType) => {
  //   // Toggle selection if clicked icon is already selected, otherwise select it
  //   const newSelectedProgram = programType === selectedProgramType ? null : programType;
  //   setSelectedProgramType(newSelectedProgram);
  
  //   const activeCardData = id === activeCard ? null : id;
  //   setActiveCard(activeCardData);
  
  //   const filters = { grants: newSelectedProgram };
  //   await fetchFilteredGrants(filters);
  // };

  const handleProgramClick = async (id, programType) => {
    if (programType === selectedProgramType) return; // Avoid redundant fetch when clicking the same program again
    
    setSelectedProgramType(programType);
    setActiveCard(id);
  
    // Fetch filtered grants only for the new program type
    await fetchFilteredGrants({ grants: programType });
    console.log({ program_type: programType });
    setFilteredGrants(programType);
    
  };

  // const handleProgramClick = async (itemText) => {
  //   if (itemText === selectedProgramType) {
  //     // If the same program text is clicked again, deselect it
  //     setSelectedProgramType(null);
  //     setActiveCard(null);
  //     setFilteredGrants([]); // Optionally clear grants if deselected
  //   } else {
  //     setSelectedProgramType(itemText);
  //     setActiveCard(itemText);
  
  //     // Fetch filtered grants only for the new program type
  //     await fetchFilteredGrants({ grants: itemText });
  //   }
  // };
  

  return (
    <View style={styles.wrapper}>
      <View style={[styles.iconsContainer ]}>
          {getVisibleIcons().map((item) => (
            <Pressable key={item.id} style={({ pressed }) => [
              styles.eachIcon,
              activeIcon === item.id && styles.pressedIcon,
              pressed && styles.pressedIcon
            ]}
            
            onPress={() => {
              handlePress(item.id); 
              handleProgramClick(item.id, item.text)
              // handleProgramClick(item.text) // Pass only item.text here
            }}
            >
              <Image style={styles.icons} source={item.icon} />
              <Text style={styles.text}>{item.text}</Text>
            </Pressable>
          ))}
      </View>
      {/* <ListGrants data={filteredGrants} /> */}
    </View >
  );

};

const styles = StyleSheet.create({
  wrapper: {
    // position: "relative",
    // flex: screenWidth < 650 ? null:  1,
    // height: 90,
    // overflow: "visible",
    // justifyContent: "center",
    // backgroundColor: 'red',
    // flexWrap: "wrap",
    // width: "100%",
    // paddingRight: 30,

    position: "relative",
    flex: screenWidth < 650 ? null:  1,
    // height: 90,
    overflow: "hidden",
    // backgroundColor: 'red',
    flexWrap: "wrap",
    width: "100%",
    paddingRight: 30,

    // overflow: "visible",
    // justifyContent: "center",

  },
  scrollWrapper: {
    overflow: "hidden",
    width: '67%',
    alignSelf: "center",
  },
  iconsContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    flexWrap: "wrap",

    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    // backgroundColor: "red",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: screenWidth < 650 ? 110:  80,
    top: 0,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "white",

    right: 0,
  },
  // gradientEffect: {
  //   position: "absolute",
  //   width: 100, 
  //   height: "100%",
  //   right: 50, 
  //   top: 0,
  //   backgroundImage:
  //     "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
  //   zIndex: 999, 
  // },
  icons: {
    height: 36,
    width: 36,
    tintColor: "#6C757D",
    alignSelf: "center",
    marginBottom: 12,
  },
  eachIcon: {
    width: 140,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: 100,
    marginLeft: 10,
  },
  text: {
    textAlign: "center",
    color: "#6C757D",
    fontFamily: "OpenSans-SemiBold",
    flexWrap: "wrap",
    fontSize: 12,

    // marginLeft: 5,
  },

  pressedIcon: {
    borderBottomWidth: 2,
    borderBottomColor: "#011f6f",
    borderRadius: 1
  }
});

export default GrantOptionsIcons;
