import { useNavigation } from '@react-navigation/native';
import React, {useState, useEffect, useRef} from 'react';
import { TouchableOpacity,FlatList, Pressable, Image, View, Text, StyleSheet, ScrollView, Dimensions  } from 'react-native';
// import Carousel from 'react-native-snap-carousel';

const screenWidth = Dimensions.get('window').width

const blogPosts = [
    {
        id: 1,
        title: '5 Mistakes to Avoid When Applying for Canadian Grants',
        image: require("../../assets/blogs/fiveMistakes/fiveMistakes.jpg"),
        link: 'FiveMistakes'
    },
    {
        id: 2,
        title: 'Education Grants in Canada: Opportunities for Students and Educators',
        image: require("../../assets/blogs/EducationGrants/educationGrants4.webp"),
        link: 'EducationGrants'
    },
    {
        id: 3,
        title: 'Environmental Business Grants: How to Fund Your Green Business in Canada',
        image: require("../../assets/blogs/EnvironmentalGrants/environmental.avif"),
        link: 'EnvironmentalBusinessGrants'
    },
    {
        id: 4,
        title: 'Essential Start-Up Grants for Canadian Entrepreneurs in 2024',
        image: require("../../assets/blogs/StartUps/startUp4.webp"),
        link: 'StartupGrantsCanada2024'
    },
    {
        id: 5,
        title: 'Grants vs. Loans in Canada: Which Financial Option is Right for You?',
        image: require("../../assets/blogs/GrantsVsLoan/grantLoans1.webp"),
        link: 'GrantsVsLoans'
    },
    {
        id: 6,
        title: 'How Black Entrepreneurs Can Access Grants to Build Thriving Businesses',
        image: require("../../assets/blogs/BlackEnterprenuers/blackEnter11.webp"),
        link: 'BlackEntrepreneursGrants'
    },
    {
        id: 7,
        title: 'How to Find Grants for Non-Profit Organizations in Canada',
        image: require("../../assets/blogs/HowToFind/howTofFind3.webp"),
        link: 'HowToFind'
    },
    {
        id: 8,
        title: 'Small Business Grants in Canada: What You Need to Know',
        image: require("../../assets/blogs/SmallBusiness/smallBusiness1.webp"),
        link: 'SmallBusinessGrantsPost'
    },
    {
        id: 9,
        title: 'Step-by-Step Guide to Applying for Grants in Canada',
        image: require("../../assets/blogs/GrantApplicationsGuide/grantApplicationsGuide1.webp"),
        link: 'GrantApplicationGuide'
    },
    {
        id: 10,
        title: 'Top Funding Sources for Canadian Startups and Entrepreneurs',
        image: require("../../assets/blogs/TopFunding/topFunding1.webp"),
        link: 'TopFundingSources'
    },
    {
        id: 11,
        title: 'Top Government Grants Available for Canadians in 2024',
        image: require("../../assets/blogs/TopGovernment/topGovernment1.webp"),
        link: 'TopGovernmentGrants'
    },
    {
        id: 12,
        title: 'Top Indigenous Grants in Canada to Support Business and Community Growth',
        image: require("../../assets/blogs/TopIndegenous/topIndigenous2.webp"),
        link: 'TopIndigenousGrants'
    },
    {
        id: 13,
        title: 'Top Resources for Finding Grants in Each Canadian Province',
        image: require("../../assets/blogs/TopResources/topResources2.webp"),
        link: 'TopResources'
    },
    {
        id: 14,
        title: 'Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada',
        image: require("../../assets/blogs/WomenInBusiness/womenGrants1.webp"),
        link: 'WomenInBusiness'
    },
];

export default function BlogPostCarousel() {
    const navigation = useNavigation();
    const flatListRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
  
    // Auto-scroll effect using useEffect and setInterval
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     setActiveIndex((prevIndex) => {
    //         const nextIndex = (prevIndex + 1) % blogPosts.length;
    //         flatListRef.current?.scrollToIndex({ index: nextIndex, animated: true });
    //         return nextIndex;
    //     });
    //     }, 3000); // Slide every 3 seconds

    //     return () => clearInterval(interval);
    // }, []);

    // Automatically scroll through items at a set interval
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        // Get next index, wrapping back to 0 when the end of the list is reached
        const nextIndex = (prevIndex + 1) % blogPosts.length;

        // Scroll to the next index
        if (flatListRef.current) {
            flatListRef.current.scrollToIndex({ index: nextIndex, animated: true });
        }

        return nextIndex;
      });
    }, 5000); // Set interval to 3 seconds (3000ms)

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, []);

    const renderItem = ({ item }) => (
        <TouchableOpacity
        style={styles.card}
        onPress={() => navigation.navigate(item.link)}  // Navigate to the respective blog post page
        >
        <Image source={item.image} style={styles.image} />
        <Text style={styles.title}>{item.title}</Text>
        </TouchableOpacity>
    );


    return (
        <View style={styles.container}>
          <Text style={styles.blogPosts}>Blog Posts</Text>
          <FlatList
            data={blogPosts}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            // horizontal
            showsHorizontalScrollIndicator={false}
            ref={flatListRef}
            onScrollToIndexFailed={(error) => {
                console.warn('Scroll failed to index:', error);
              }}
            extraData={activeIndex}
            contentContainerStyle={styles.flatlistContainer}
          />
        </View>
    );
    
};


const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    //   backgroundColor: "red",
    //   width: "100%",
      maxHeight: 315,
    },
    flatlistContainer: {
        // flexDirection: 'row',  // Ensuring items are in a horizontal row
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      backgroundColor: '#fff',
      borderRadius: 10,
      overflow: 'hidden',
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.8,
      shadowRadius: 3,
      elevation: 5,
      padding: 10,
      marginHorizontal: 10,
      marginVertical: 10,
      width: 250,
      height: 255,
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: 200,
      height: 150,
      borderRadius: 10,
      margin: 10
    },
    blogPosts:{
        // marginTop: 10,
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center', 
        color: "#011F6F"
    },
    title: {
      marginTop: 10,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      color: "#011F6F"
    },
});