import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const screenWidth = Dimensions.get('window').width

export default function GrantsVsLoans () {
  return (
    <>
        <Header/>

        {/* Title and Introduction */}
        <View style={styles.headerSection}>
            <Text style={styles.title}>Grants vs. Loans in Canada: Which Financial Option is Right for You?</Text>
        </View>

        <ScrollView style={styles.container}
            showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
            showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
        >
            <View style={styles.sectionContainer}>
                <View style={styles.section}>
                <Image style={styles.image} source={require("../../../assets/blogs/GrantsVsLoan/grantLoans1.webp")}/>
                <Text style={styles.introText}>
                    When looking for funding options in Canada, grants and loans are two of the most common financial tools available. Both have distinct advantages and drawbacks, 
                    and the choice between them depends on your financial needs, long-term goals, and ability to meet repayment obligations.
                    </Text>
                </View>

                {/* Grants Section */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Grants</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/GrantsVsLoan/grantLoan2.webp")}/>
                    <Text style={styles.text}>
                    Grants are non-repayable funds provided by the government, private organizations, or non-profits to support specific activities, such as business growth, education, or research.
                    One of the biggest benefits of grants is that they do not need to be repaid, making them a highly attractive option for those who qualify. The Canadian government, for example, offers grants 
                    through programs like the Canada Student Grants (CSG) and various business initiatives, such as the Women Entrepreneurship Strategy.
                    </Text>
                    <Text style={styles.text}>
                    However, grants are highly competitive, and the application process is often lengthy and rigorous. They also typically come with restrictions on how the funds must be used, which can limit flexibility in how the money is applied to your project.
                    </Text>
                    <Text style={styles.text}>
                    Securing a grant requires aligning your goals with the funder's priorities. Grants are often given to projects or initiatives that align with the granting body's mission, such as supporting innovation, education, sustainability, or economic development. 
                    To be successful in obtaining a grant, applicants need to provide a clear and detailed plan for how the funds will be used, outlining measurable goals, timelines, and expected outcomes.
                    </Text>
                </View>

                {/* Loans Section */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Loans</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/GrantsVsLoan/grantLoan3.webp")}/>
                    <Text style={styles.text}>
                    Loans, unlike grants, are funds borrowed from a lender such as a bank, credit union, or government program that must be repaid with interest. Loans are often easier to secure than grants because they come with fewer eligibility restrictions, and approval is typically based on your creditworthiness and ability to repay.
                    </Text>
                    <Text style={styles.text}>
                    Government-backed loans, such as the Canada Small Business Financing Program and Canada Student Loans, offer favorable terms for borrowers, including lower interest rates or flexible repayment schedules. Loans offer more flexibility in how funds are used, as they usually come with fewer restrictions than grants, allowing you to apply the money to various business expenses, educational costs, or personal needs.
                    </Text>
                    <Text style={styles.text}>
                    The primary downside of loans is the repayment requirement. Borrowers must repay the loan amount plus interest over a set period, which can add financial pressure in the long term. The interest rates and terms vary depending on the lender, your credit score, and the loan type.
                    </Text>
                </View>

                {/* Comparing Grants and Loans Section */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Comparing Grants and Loans</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")}/>
                    <Text style={styles.text}>
                    When deciding between grants and loans, it's essential to evaluate your financial situation, the purpose of the funding, and your long-term goals. Grants are ideal if you meet the eligibility criteria and can align your project with the grant provider’s objectives.
                    </Text>
                    <Text style={styles.text}>
                    They offer the benefit of non-repayable funding but are harder to secure due to the competitive nature of grant applications and the specific restrictions on how the funds are used.
                    </Text>
                    <Text style={styles.text}>
                    Loans, on the other hand, provide more flexibility and faster access to funds. They are an excellent option if you need financial support quickly or if you require more control over how the money is spent. However, loans come with repayment obligations and interest costs, which can place a burden on your future finances.
                    </Text>
                    <Text style={styles.text}>
                    Another factor to consider is the timeline. Grants typically require a longer application process, and the funds may take time to be awarded, making them less suitable for urgent financial needs. Loans can usually be obtained more quickly, making them the better option if you need immediate financial support.
                    </Text>
                </View>

                {/* Conclusion Section */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Conclusion</Text>
                    <Text style={styles.text}>
                    Choosing between a grant and a loan in Canada depends on your specific needs and financial situation. Grants offer the major benefit of non-repayable funding but are highly competitive and come with usage restrictions. They are ideal for those who qualify and are looking for debt-free financial support.
                    </Text>
                    <Text style={styles.text}>
                    Loans, while requiring repayment with interest, provide flexibility and quicker access to funds, making them suitable for a wider range of projects and financial needs. 
                    By carefully evaluating your goals, financial stability, and the conditions of each option, you can make an informed decision that best supports your long-term success.
                    </Text>
                </View>

                {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For a comprehensive look at funding options, including both grants and loans, you can visit
                    <TouchableOpacity onPress={() => Linking.openURL('https://www.findgrant.ca')}>
                    <Text style={styles.ctaLink}> FindGrant.ca</Text>
                    </TouchableOpacity>.
                    </Text>
                </View> */}

                {/* Call to Action */}
                <View style={[styles.cta, ]}>
                    <Text style={styles.ctaText}>
                    For a comprehensive look at funding options, including both grants and loans, you can visit {' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                        <Text style={styles.ctaLink}>FindGrant.ca</Text>
                        </TouchableOpacity>.
                    </Text>
                </View>
            </View>
            <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f9f9f9',
    // padding: 16,
  },
  headerSection: {
    paddingTop: 10,
  },
  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
