import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView, Dimensions, TouchableOpacity, Linking   } from 'react-native';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const screenWidth = Dimensions.get('window').width

export default function TopFundingSources() {
  return (
    <>
      <Header />

      {/* Title */}
      <View style={styles.headerSection}>
        <Text style={styles.title}>Top Funding Sources for Canadian Startups and Entrepreneurs </Text>
      </View>
      
      {/* <View style={styles.header}>
        <Text style={styles.headerText}> Top Funding Sources for Canadian Startups and Entrepreneurs </Text>
      </View> */}

      <ScrollView style={styles.container}
      showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
      showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
      >
        <View style={styles.sectionContainer}>
          <View style={styles.section}>
          <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFunding1.webp")}/>
          <Text style={styles.paragraph}>
            Finding the right funding is one of the most critical steps for Canadian startups and entrepreneurs. Canada offers a wealth of financial resources, from government grants and venture capital to crowdfunding and business loans. Each funding source has its benefits and caters to different stages of business development, whether you're launching a startup or scaling up an established business. Knowing how to navigate these options can provide the financial backing necessary to fuel your growth and bring your ideas to market.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 1 */}
            <Text style={styles.sectionTitle}>Government Grants and Programs</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingGovernment.webp")}/>
            <Text style={styles.paragraph}>
            Canadian entrepreneurs have access to a range of government-backed grants and funding programs designed to encourage business development and innovation. One prominent program is the Canada Small Business Financing Program (CSBFP), which supports startups by offering loans to purchase assets or improve facilities. Another notable resource is Futurpreneur Canada, which provides funding, mentorship, and support to young entrepreneurs aged 18 to 39. They offer start-up loans of up to $60,000 along with business coaching.
            For businesses focused on research, development, or innovation, the National Research Council of Canada Industrial Research Assistance Program (NRC IRAP) provides funding and advisory services to small and medium-sized enterprises (SMEs). Innovative Solutions Canada (ISC) is another key program that provides grants and procurement opportunities for companies that offer innovative solutions to government challenges.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 2 */}
            <Text style={styles.sectionTitle}>Venture Capital and Angel Investors</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingVenture.webp")}/>
            <Text style={styles.paragraph}>
            Venture capital (VC) and angel investors are critical sources of funding for startups with high growth potential. VC firms such as BDC Capital, OMERS Ventures, and Real Ventures invest in Canadian startups, particularly those in the technology, healthcare, and cleantech sectors. These firms provide capital in exchange for equity, enabling businesses to scale quickly.
            Angel investors, typically high-net-worth individuals, provide early-stage funding to startups and are often more willing to take risks than traditional lenders. Angel networks like Maple Leaf Angels and Angel One Network connect startups with potential investors who can provide both funding and strategic guidance. Angel investment not only provides capital but also opens doors to mentorship and valuable industry connections.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 3 */}
            <Text style={styles.sectionTitle}>Incubators and Accelerators</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingIncubators.webp")}/>
            <Text style={styles.paragraph}>
            Incubators and accelerators offer startups more than just financial support—they provide mentorship, networking opportunities, and workspace. Some of Canada's leading accelerators, such as MaRS Discovery District and Creative Destruction Lab, specialize in helping startups navigate early growth stages. These programs often culminate in pitch events where startups can present their business to a group of investors. Accelerators typically offer seed funding in exchange for equity, but the value of their networks and guidance often outweighs the cost.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 4 */}
            <Text style={styles.sectionTitle}>Crowdfunding</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingCrowdfunding.webp")}/>
            <Text style={styles.paragraph}>
            Crowdfunding has become a popular funding avenue for entrepreneurs looking to raise small amounts from a large group of people. Platforms like Kickstarter, Indiegogo, and GoFundMe allow startups to pitch their product or service directly to consumers. In exchange for small contributions, backers may receive early access to products or exclusive offers. Crowdfunding is particularly useful for validating demand, building a customer base, and securing early-stage funding without giving up equity.
            Equity crowdfunding is another option for startups looking to raise larger sums of money by offering shares in the business. Platforms like FrontFundr and SeedUps Canada allow entrepreneurs to sell equity stakes to the public in exchange for investment, offering an alternative to traditional venture capital funding.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 5 */}
            <Text style={styles.sectionTitle}>Business Loans</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingBusinessLoans.webp")}/>
            <Text style={styles.paragraph}>
            For startups that require predictable funding without giving up equity, business loans are a reliable option. Government-backed loans, such as those provided by the Business Development Bank of Canada (BDC), cater specifically to entrepreneurs and startups. BDC offers loans that cover startup costs, working capital, and business expansion. The interest rates are typically favorable, and the terms can be more flexible compared to traditional bank loans.
            Canadian banks, such as RBC, TD, and CIBC, also provide business loans tailored to startups, often requiring a solid business plan and good credit history. These loans help entrepreneurs fund everything from inventory to marketing and can be used for day-to-day operations or long-term growth initiatives.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 6 */}
            <Text style={styles.sectionTitle}>Angel Networks and Investment Groups</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingAngelNetworks.webp")}/>
            <Text style={styles.paragraph}>
            In addition to venture capital and private angel investors, there are also formalized networks of investors who specialize in providing early-stage funding to Canadian startups. Angel Investment Networks like Golden Triangle Angel Network (GTAN) and Ontario Network of Entrepreneurs (ONE) are platforms that connect startups with angel investors. These networks can offer both the funding needed to grow a business and the guidance required to navigate the complexities of startup development.
            Angel networks are particularly valuable because they often include mentorship, introductions to other investors, and a wealth of industry experience. Startups that receive angel funding typically benefit from having experienced investors who offer strategic advice alongside capital.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 7 */}
            <Text style={styles.sectionTitle}>Corporate Investment and Strategic Partnerships</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopFunding/topFundingCorporate.webp")}/>
            <Text style={styles.paragraph}>
            Many large corporations in Canada are increasingly looking to invest in or partner with startups that align with their strategic goals. Programs like RBC Ventures or Telus Ventures are examples of corporate ventures that invest in innovative startups, particularly in areas like fintech, health, and technology. In these partnerships, corporations may provide funding, resources, and access to their customer base in exchange for equity or collaborative development. Corporate funding can be particularly beneficial for startups seeking rapid scalability through strategic alliances with established brands.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Conclusion</Text>
            <Text style={styles.paragraph}>
            Canadian startups and entrepreneurs have access to a diverse range of funding sources that can help turn their ideas into successful businesses. By leveraging government grants, venture capital, incubators, crowdfunding, and business loans, entrepreneurs can secure the financial support they need to grow and thrive in Canada’s competitive business landscape. Understanding the different funding options available and how to apply for them is crucial for navigating the early stages of business development and expansion. With careful planning and the right funding, Canadian entrepreneurs can set themselves up for long-term success.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Call to Action */}
            <View style={[styles.cta, ]}>
              <Text style={styles.ctaText}>
              For a comprehensive guide on funding sources tailored to Canadian startups, you can visit,{' '}
                <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                <Text style={styles.ctaLink}>FindGrant.ca</Text>
                </TouchableOpacity> where they provide access to a variety of grants and funding options for entrepreneurs, covering government grants, private funding, and venture capital.
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 15,
    backgroundColor: '#fff',
    backgroundColor: '#f9f9f9',
  },
  header: {
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  headerSection: {
    paddingTop: 10,
  },
  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },

  title: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },

  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },

  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  paragraph: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
