import React from 'react';
import { ScrollView, Text, View, StyleSheet, Image, Dimensions, TouchableOpacity, Linking  } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const screenWidth = Dimensions.get('window').width
export default function HowToFind () {
  return (
    <>
        <Header/>

        {/* Title */}
        <View style={styles.headerSection}>
            <Text style={styles.title}>How to Find Grants for Non-Profit Organizations in Canada</Text>
        </View>

        <ScrollView style={styles.container}
        showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
        showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
        >


            <View style={styles.sectionContainer}>
                <View style={styles.section} >
                    <Image style={styles.image} source={require("../../../assets/blogs/HowToFind/howTofFind3.webp")}/>
                    {/* Introduction */}
                    <Text style={styles.paragraph}>
                    Finding grants for non-profit organizations in Canada is crucial for obtaining financial support for important
                    community, social, or environmental initiatives. By understanding where to look and how to navigate the application
                    process, you can enhance your chances of securing valuable funding.
                    </Text> 
                </View>
                
                <View style={styles.section} >
                    {/* Section 1: Government Grants */}
                    <Text style={styles.sectionTitle}>Government Grants for Non-Profits</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/HowToFind/howToFind21.jpeg")}/>
                    <Text style={styles.paragraph}>
                    Government funding is one of the most reliable sources of financial support for non-profits in Canada. Start by
                    exploring the Government of Canada’s funding programs database, which includes grants for a wide variety of sectors
                    such as health, community development, arts, and the environment. The federal government offers programs like
                    Canada’s Social Development Partnerships Program and New Horizons for Seniors Program, which provide funding for
                    social development and community-building initiatives. Each province also offers specific regional grants, so it’s
                    beneficial to research your local government’s funding opportunities. Don’t forget about municipal grants, which often
                    support local, smaller-scale projects aimed at community improvement or engagement.
                    </Text>
                </View>

                <View style={styles.section} >
                    {/* Section 2: Private Foundations and Charitable Organizations */}
                    <Text style={styles.sectionTitle}>Private Foundations and Charitable Organizations</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/HowToFind/howToFind31.webp")}/>
                    <Text style={styles.paragraph}>
                    Beyond government grants, non-profits can tap into funding from private foundations. Major Canadian foundations like the
                    McConnell Foundation, Trillium Foundation, and J.W. McConnell Family Foundation provide generous grants to non-profits
                    working in areas such as social justice, climate change, and community resilience. To streamline the search process, you
                    can use grant databases like Grant Connect and Imagine Canada, which allow you to search for grant opportunities by
                    sector, region, and type of project. These platforms connect you to thousands of funders across Canada, helping you
                    identify those that best match your mission.
                    </Text>
                </View>

                <View style={styles.section} >
                    {/* Section 3: Networking and Partnerships */}
                    <Text style={styles.sectionTitle}>Networking and Partnerships</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/HowToFind/howToFind41.webp")}/>
                    <Text style={styles.paragraph}>
                    Networking with other non-profits and community organizations is an often overlooked but effective way to find new grant
                    opportunities. By collaborating with other organizations in your sector, you can learn about upcoming grants, share best
                    practices for applying, and even form partnerships that strengthen your applications. Joining non-profit associations or
                    attending events such as conferences and workshops can help you meet potential partners or funders. Many non-profits share
                    valuable insights on how they secured funding, which can provide you with new strategies and tips for successful grant
                    writing.
                    </Text>
                </View>

                <View style={styles.section} >
                    {/* Section 4: Writing a Winning Grant Proposal */}
                    <Text style={styles.sectionTitle}>Writing a Winning Grant Proposal</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/HowToFind/howToFind51.webp")}/>
                    <Text style={styles.paragraph}>
                    Once you’ve found potential grants, writing a strong proposal is critical. Begin by ensuring your application clearly
                    outlines the mission of your non-profit, the project that requires funding, and the measurable impact it will have. Funders
                    need to understand how their money will be used and the tangible benefits it will create for the community. A detailed
                    project plan, complete with a budget and timeline, is essential for demonstrating that you are organized and capable of
                    successfully executing your proposed initiative.
                    </Text>
                </View>

                <View style={styles.section} >
                    {/* Conclusion */}
                    <Text style={styles.sectionTitle}>Conclusion</Text>
                    <Text style={styles.paragraph}>
                    Finding grants for non-profit organizations in Canada requires strategic research, planning, and an understanding of the
                    specific goals and values of each grant provider. By exploring government programs, private foundations, and fostering
                    partnerships within the non-profit sector, you can significantly enhance your ability to secure funding. Writing strong,
                    tailored applications that highlight your non-profit’s mission and impact is key to standing out in a competitive
                    environment. With careful planning and persistence, you can find the financial support needed to bring your non-profit’s
                    projects to life and make a lasting difference in your community.
                    </Text>
                </View>
                    {/* 
                <View style={styles.section} >
                    <Text style={styles.callToAction}>
                    For non-profit organizations in Canada seeking grants, FindGrant.ca serves as a comprehensive resource. It offers access to
                    funding opportunities across sectors, including specific grants for social development, community-building, and
                    environmental projects.
                    </Text>
                </View> */}

                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For non-profit organizations in Canada seeking grants,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity> serves as a comprehensive resource. It offers access to
                            funding opportunities across sectors, including specific grants for social development, community-building, and
                            environmental projects.
                        </Text>
                    </View>
                </View>
            </View>
            <Footer/>
        </ScrollView>
    </>
  );
};

// Styles for the Blog Post
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 15,
    backgroundColor: '#fff',
    backgroundColor: '#f9f9f9',
  },
  headerSection: {
    paddingTop: 10,
  },
  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  heroImage: {
    width: '100%',
    height: 300,
    marginBottom: 20,
    resizeMode: 'cover',
  },
  callToAction: {
    fontSize: 16,
    fontWeight: '600',
    color: '#0066CC',
    marginTop: 20,
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
