import React, { useState, useEffect } from "react";

import { fetchFundingPrograms } from "../../api/FetchFundingPrograms";
import { Text } from "react-native";
import ListGrantsDropDown from "../../components/ListGrantsDropDown";

const ArtCulture = () => {
  const [grantsData, setGrantsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contentIds = [
          126, 2957, 704, 722, 728, 732, 737, 739, 824, 1007, 1394,
        ];
        const fetchedData = await Promise.all(
          contentIds.map((id) => fetchFundingPrograms(id))
        );

        const formattedData = fetchedData.map((grant) => [
          grant.programName,
          grant.programSource,
          grant.description,
          grant.short_urls,
        ]);

        setGrantsData(formattedData);
      } catch (error) {
        console.error("Error fetching Art & Culture grants:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return <ListGrantsDropDown category="artCulture" grantsData={grantsData} />;
};

export default ArtCulture;
