import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity,Dimensions, Linking } from 'react-native';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const screenWidth = Dimensions.get('window').width

export default function TopIndigenousGrants() {
  return (
    <>
      <Header />
      <View style={styles.header}>
          <Text style={styles.headerText}>Top Indigenous Grants in Canada to Support Business and Community Growth</Text>
        </View>

      <ScrollView style={styles.container}
      showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
      showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
      >
        <View style={styles.sectionContainer}>
          <View style={styles.section}>
          <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenous2.webp")}/>
          <Text style={styles.paragraph}>
            Indigenous communities and entrepreneurs in Canada can access a wide range of grants designed to encourage business development, innovation, and community growth. These programs aim to close economic gaps, foster entrepreneurship, and support long-term economic self-sufficiency. Whether you're launching a business, expanding operations, or looking to enhance your community's infrastructure, there are funding opportunities available that cater to the unique needs of Indigenous peoples across Canada.
            </Text>
          </View>
          
          <View style={styles.section}>
            {/* Section 1 */}
            <Text style={styles.sectionTitle}>Indigenous Entrepreneurship Program</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenous1.webp")}/>
            <Text style={styles.paragraph}>
            This program is designed to help Indigenous entrepreneurs start or grow their businesses. It provides grants and access to advisory services to develop business plans, marketing strategies, and operations. Whether you are in the early stages of launching a business or looking to expand, this program offers the support needed to succeed. Funding can be used for a variety of purposes, from purchasing equipment to covering startup costs.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 2 */}
            <Text style={styles.sectionTitle}>Aboriginal Business and Entrepreneurship Development (ABED)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousAboriginal.webp")}/>
            <Text style={styles.paragraph}>
            The ABED program offers grants and loans specifically to Indigenous businesses. Its focus is on fostering economic development in Indigenous communities by supporting businesses in sectors like technology, tourism, and natural resources. ABED provides financial support for both new ventures and expanding businesses, encouraging Indigenous entrepreneurship while helping to create jobs and sustain community development.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 3 */}
            <Text style={styles.sectionTitle}>First Nations Development Fund (Alberta)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousAlberta.webp")}/>
            <Text style={styles.paragraph}>
            Available to First Nations communities in Alberta, this fund supports projects that enhance economic, social, and cultural well-being. Grants can be used for infrastructure, community programs, business ventures, and cultural projects. The goal of the fund is to empower First Nations communities to pursue sustainable growth, improve the quality of life, and foster business innovation at the local level.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 4 */}
            <Text style={styles.sectionTitle}>Indigenous Skills and Employment Training Program (ISET)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousIndigenousSkills.webp")}/>
            <Text style={styles.paragraph}>
            The ISET program focuses on providing Indigenous people with the skills necessary to succeed in the workforce. Through grants to community organizations, ISET supports training programs that help individuals develop marketable skills in various industries. This program not only helps build career readiness but also promotes long-term economic development by empowering Indigenous communities to participate fully in the Canadian economy.
            </Text>
          </View>
          
          <View style={styles.section}>
            {/* Section 5 */}
            <Text style={styles.sectionTitle}>Community Opportunity Readiness Program (CORP)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousCommunityOpportunity.webp")}/>
            <Text style={styles.paragraph}>
            The Community Opportunity Readiness Program is designed to provide financial support for Indigenous communities pursuing economic development projects. CORP helps communities prepare for and implement business ventures that create jobs and foster economic independence. Funding can be used for feasibility studies, business planning, or startup costs. This program encourages Indigenous communities to take control of their economic future by building sustainable businesses.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 6 */}
            <Text style={styles.sectionTitle}>Métis Nation-Specific Programs</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousMetisNation.webp")}/>
            <Text style={styles.paragraph}>
            Several grants and financial resources are available specifically for Métis entrepreneurs and organizations. The Métis Nation of Ontario (MNO) and the Louis Riel Capital Corporation are key providers of business financing and grants for Métis-owned ventures. These programs are designed to assist Métis entrepreneurs in starting and growing their businesses, helping to ensure economic inclusion and opportunity.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 7 */}
            <Text style={styles.sectionTitle}>Canada's Strategic Partnerships Initiative (SPI)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousCanadaStrategic.webp")}/>
            <Text style={styles.paragraph}>
            The Strategic Partnerships Initiative is a federal program that supports Indigenous involvement in large-scale economic projects across Canada. It facilitates partnerships between Indigenous businesses and industries in sectors like energy, mining, and infrastructure development. Through SPI, Indigenous communities can access funding to help participate in significant national projects, ensuring that they benefit from economic opportunities linked to these industries.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Section 8 */}
            <Text style={styles.sectionTitle}>Ulnooweg Development Group</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousUlnooweg.webp")}/>
            <Text style={styles.paragraph}>
            The Ulnooweg Development Group provides financing and advisory services to Indigenous entrepreneurs in Atlantic Canada. Ulnooweg offers loans, business advisory services, and grants to support Indigenous businesses in a wide range of sectors, including fisheries, manufacturing, and technology. By supporting Indigenous-owned businesses, Ulnooweg helps foster economic development and sustainability within Atlantic Canada's Indigenous communities.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>First Peoples Economic Growth Fund (Manitoba)</Text>
            <Image style={styles.image} source={require("../../../assets/blogs/TopIndegenous/topIndigenousFirstPeople.webp")}/>
            <Text style={styles.paragraph}>
            The First Peoples Economic Growth Fund is a Manitoba-based initiative that supports Indigenous businesses through grants, loans, and equity investments. This fund is dedicated to assisting Indigenous entrepreneurs with financing for new business ventures, business expansions, and partnerships. The program also offers funding for feasibility studies, business plan development, and market research, ensuring that Indigenous business owners have the tools they need to succeed.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Conclusion</Text>
            <Text style={styles.paragraph}>
            Canada offers a wide range of Indigenous-focused grants aimed at supporting business and community growth. These grants are vital for fostering economic development, providing opportunities for entrepreneurship, and promoting self-sufficiency within Indigenous communities. Programs like the Indigenous Entrepreneurship Program, ABED, and the First Nations Development Fund help businesses launch and thrive, while initiatives like ISET and SPI ensure that Indigenous individuals and communities can participate fully in the national economy. By tapping into these resources, Indigenous entrepreneurs and community leaders can build sustainable economic opportunities and contribute to the long-term prosperity of their communities.
            </Text>
          </View>

          <View style={styles.section}>
            {/* Call to Action */}
            <View style={[styles.cta, ]}>
              <Text style={styles.ctaText}>
              For information on Indigenous grants in Canada, including programs focused on business and community growth, you can visit the dedicated Indigenous Grants page on,{' '}
                <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/IndigenousGrants')}>
                <Text style={styles.ctaLink}>FindGrant.ca</Text>
                </TouchableOpacity>. This section provides insights into various funding opportunities available for Indigenous entrepreneurs and communities, such as the Indigenous Entrepreneurship Program, the Community Opportunity Readiness Program, and other grants supporting business development, sustainability, and cultural preservation.
              </Text>
            </View>
          </View>

        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 15,
    backgroundColor: '#fff',
    backgroundColor: '#f9f9f9',
  },
  headerSection: {
    paddingTop: 10,
  },
  header: {
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  paragraph: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },

  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
