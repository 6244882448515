import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const screenWidth = Dimensions.get('window').width

export default function EnvironmentalBusinessGrants() {
  return (
    <>
        <Header/>

        <View style={styles.headerSection}>
            <Text style={styles.title}>Environmental Business Grants: How to Fund Your Green Business in Canada</Text>
        </View>

        <ScrollView style={styles.container}
          showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
          showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
        >
            <View style={styles.sectionContainer}>
                <View style={styles.section}>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}/>
                    <Text style={styles.introText}>
                    As more entrepreneurs focus on sustainability and environmental stewardship, Canada offers a variety of grants to support green businesses.
                    These funding opportunities are designed to encourage eco-friendly initiatives, innovation, and sustainable practices across various sectors. 
                    Here’s how you can fund your green business in Canada through key environmental grants and programs.
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Canada’s Zero Emission Vehicle Infrastructure Program (ZEVIP)</Text>
                    {/* <Image source={{ uri: 'https://example.com/ev-charging.jpg' }} style={styles.image} /> */}
                    <Text style={styles.text}>
                    The Zero Emission Vehicle Infrastructure Program helps fund the installation of electric vehicle charging stations across Canada.
                    Green businesses that plan to incorporate EV charging solutions or switch to electric fleets can access funding through this program.
                    The grants cover up to 50% of the project costs, encouraging the adoption of electric vehicles and supporting sustainability goals.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Canada Clean Tech Investment Fund</Text>
                    {/* <Image source={{ uri: 'https://example.com/clean-tech.jpg' }} style={styles.image} /> */}
                    <Text style={styles.text}>
                    The Clean Tech Investment Fund supports startups and small businesses that develop clean technology solutions. This fund focuses on projects
                    that reduce carbon emissions, improve energy efficiency, or address environmental challenges. Businesses can use the funding for R&D,
                    scaling operations, or bringing new green technologies to market.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Sustainable Development Technology Canada (SDTC)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental5.webp")}/>
                    <Text style={styles.text}>
                    Sustainable Development Technology Canada offers funding to businesses focused on developing and commercializing clean technologies.
                    The SDTC helps green businesses move from idea to market, supporting innovations that address climate change, reduce waste, and promote
                    sustainable practices. This grant is ideal for startups in clean energy, waste management, and green manufacturing sectors.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Green Municipal Fund (GMF)</Text>
                    {/* <Image source={{ uri: 'https://example.com/municipal-sustainability.jpg' }} style={styles.image} /> */}
                    <Text style={styles.text}>
                    The Green Municipal Fund, managed by the Federation of Canadian Municipalities, supports projects that improve environmental performance in
                    Canadian municipalities. Businesses working on projects that benefit cities—like waste reduction, water conservation, or renewable energy
                    solutions—can access grants or low-interest loans through this program. GMF funding helps green businesses collaborate with municipalities
                    to achieve local sustainability goals.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Canada Nature Fund</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental3.avif")}/>
                    <Text style={styles.text}>
                    For businesses focusing on conservation and biodiversity, the Canada Nature Fund offers grants to projects that protect and restore ecosystems.
                    The fund provides financial support to initiatives that aim to preserve wildlife, restore natural habitats, and enhance biodiversity
                    across Canada. Green businesses engaged in conservation efforts can leverage this funding to expand their operations or launch new projects.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Agricultural Clean Technology Program</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental6.webp")}/>
                    <Text style={styles.text}>
                    The Agricultural Clean Technology Program is designed to help businesses in the agriculture sector adopt clean technologies that reduce
                    environmental impacts. The program offers grants for projects that focus on energy efficiency, soil health, and sustainable farming practices.
                    Farmers and agri-businesses can access funding to invest in technologies that lower their carbon footprint and improve sustainability.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Innovative Solutions Canada (ISC) – Clean Tech Stream</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental1.avif")}/>
                    <Text style={styles.text}>
                    Innovative Solutions Canada has a dedicated Clean Tech Stream that provides grants to businesses developing solutions for environmental challenges.
                    This stream focuses on innovation, encouraging the creation of products and services that improve energy efficiency, reduce emissions, or solve
                    other sustainability-related problems. Companies selected by the ISC can receive funding for R&D and commercialization of their cleantech solutions.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>The Low Carbon Economy Fund</Text>
                    {/* <Image source={{ uri: 'https://example.com/low-carbon.jpg' }} style={styles.image} /> */}
                    <Text style={styles.text}>
                    The Low Carbon Economy Fund is a federal initiative that provides funding to projects that reduce greenhouse gas emissions.
                    Businesses that develop or implement technologies or practices that contribute to Canada’s climate goals can apply for grants
                    through this program. The fund supports projects across various sectors, including manufacturing, agriculture, and transportation,
                    promoting initiatives that lead to cleaner, more sustainable operations.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Natural Resources Canada (NRCan) Energy Efficiency Programs</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/EnvironmentalGrants/environmental2.avif")}/>
                    <Text style={styles.text}>
                    Natural Resources Canada offers a range of energy efficiency programs that provide financial support to businesses implementing
                    energy-saving measures. Whether upgrading equipment, retrofitting buildings, or investing in renewable energy solutions, green businesses
                    can access incentives and grants to reduce their energy consumption. Programs like the Energy Manager Program and Industrial Energy Efficiency
                    Program help companies lower operational costs while contributing to environmental sustainability.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Navigating the Application Process</Text>
                    <Text style={styles.text}>
                    Applying for environmental grants can be competitive, so preparation is essential. Start by researching the specific requirements and eligibility
                    criteria for each grant program. Develop a clear, detailed business plan that outlines your project, how it aligns with the goals of the grant,
                    and the impact it will have on sustainability. Many grant applications require technical details and cost estimates, so be sure to provide accurate
                    data and projections. Strong applications demonstrate how the project will contribute to Canada’s environmental goals while offering a viable
                    business opportunity.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Leveraging Partnerships and Networks</Text>
                    <Text style={styles.text}>
                    Forming partnerships with other businesses, municipalities, or research institutions can increase your chances of securing funding.
                    Collaborative projects that involve multiple stakeholders often have a greater impact and can make a stronger case for funding.
                    Networking within the green business community can also help you discover new opportunities, share best practices, and learn from other
                    entrepreneurs who have successfully secured grants.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.subtitle}>Conclusion</Text>
                    <Text style={styles.text}>
                    Canada offers a wealth of funding opportunities for green businesses looking to make a positive environmental impact. Whether you’re developing clean technologies,
                    improving energy efficiency, or focusing on conservation, these grants can provide the financial support you need to grow your business.
                    By understanding the requirements, preparing a solid business plan, and leveraging networks, entrepreneurs can navigate the application process and access
                    essential funding to build sustainable, successful businesses. With the right approach, Canadian green businesses can contribute to a more sustainable future
                    while achieving long-term growth and profitability.
                    </Text>
                </View>

                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For information on funding your green business through environmental grants in Canada, a targeted resource is available at FindGrant.ca's Environmental Business Grants section.
                    This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                            For information on funding your green business through environmental grants in Canada, a targeted resource is available at {' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/EnvironmentalGrants')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>'s Environmental Business Grants section. This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                        </Text>
                    </View>
                </View>
            </View>
            <Footer/>
        </ScrollView>
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f9f9f9',
  },
  headerSection: {
    paddingTop: 10,
  },
  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },

  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
