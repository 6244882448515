import React, { useRef, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import SearchBar from "../components/SearchBar";
import WhyFindGrant from "../components/WhyFindGrant";
import HowFindGrant from "../components/HowFindGrant";
import Layout from "../components/Layout.jsx";
import { useRoute, useNavigation } from "@react-navigation/native";
import { Helmet } from 'react-helmet';
import BlogPostCarousel from "../components/BlogCarousel";

const Home = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { scrollToSearchBar } = route.params || {};
  const searchBarRef = useRef(null);
  const scrollViewRef = useRef(null);

  const title = 'FindGrant.ca';
  const description = 'FindGrant is your gateway to diverse funding opportunities across Canada, including small business, research, sustainability, and BIPOC grants.' ;
  const imageUrl = 'https://beta.findgrant.ca/static/media/FindGrant%20Logo.2044c10034f73015917e.png';

  const shouldUseHelmet = false; // Set this logic based on your requirements

  useEffect(() => {
    if (scrollToSearchBar && searchBarRef.current && scrollViewRef.current) {
      searchBarRef.current.measure((x, y, width, height, pageX, pageY) => {
        scrollViewRef.current.scrollTo({ y: pageY, animated: true });

        // Resets state after scrolling
        navigation.setParams({ scrollToSearchBar: false });
      });
    }
  }, [scrollToSearchBar, navigation]);
  return (
    <Layout>
      {shouldUseHelmet && (
          <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content= {description}/>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content="https://beta.findgrant.ca" />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Header />
      <Hero />
      <View style={styles.searchView} ref={searchBarRef}>
        <SearchBar />
      </View>
      <WhyFindGrant />
      <HowFindGrant />
      <BlogPostCarousel/>
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF", // applies white default background
  },
  searchView: {
    width: "100%",
    alignItems: "center",
  },
  content: {
    flex: 1,
  },
});

export default Home;
