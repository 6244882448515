import "@expo/metro-runtime";
import React, { useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Home from "./src/screens/Home";
import { ScrollView } from "react-native";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import Contact from "./src/screens/Contact";
import AboutUs from "./src/screens/AboutUs";

import Women from "./src/screens/ListGrantsTypes/Women";
import IndigenousGrants from "./src/screens/ListGrantsTypes/IndigenousGrants";
import BlackEntrepreneur from "./src/screens/ListGrantsTypes/BlackEntrepreneur";
import Environmental from "./src/screens/ListGrantsTypes/Environmental";
import SmallBusiness from "./src/screens/ListGrantsTypes/SmallBusiness";
import ArtCulture from "./src/screens/ListGrantsTypes/ArtCulture";
import StartUp from "./src/screens/ListGrantsTypes/StartUp";
import Technology from "./src/screens/ListGrantsTypes/Technology";
import ProgramSearch from "./src/screens/ProgramSearch";

import FiveMistakes from "./src/screens/Blogs/FiveMistakes";
import EducationGrants from "./src/screens/Blogs/EducationGrants";
import EnvironmentalBusinessGrants from "./src/screens/Blogs/EnvironmentalBusinessGrants";
import StartupGrantsCanada2024 from "./src/screens/Blogs/StartupGrantsCanada2024";
import GrantsVsLoans from "./src/screens/Blogs/GrantsVsLoans";
import BlackEntrepreneursGrants from "./src/screens/Blogs/BlackEntrepreneursGrants";
import HowToFind from "./src/screens/Blogs/HowToFind";
import SmallBusinessGrantsPost from "./src/screens/Blogs/SmallBusinessGrantsPost";
import GrantApplicationGuide from "./src/screens/Blogs/GrantApplicationGuide";
import GrantPageDetails from "./src/screens/GrantPageDetails";
import TopFundingSources from "./src/screens/Blogs/TopFundingSources";
import TopGovernmentGrants from "./src/screens/Blogs/TopGovernmentGrants";
import TopIndigenousGrants from "./src/screens/Blogs/TopIndigenousGrants"; 
import TopResources from "./src/screens/Blogs/TopResources";
import WomenInBusiness from "./src/screens/Blogs/WomenInBusiness";
// import MetaTags from ",/MetaTags";

// import { Picker } from "@react-native-picker/picker"; // Make sure to install this package

SplashScreen.preventAutoHideAsync();

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ["http://localhost:8081"],
  config: {
    screens: {
      Home: "",
      Contact: "contactUs",
      AboutUs: "AboutUs",

      Women: "WomanOwnedBusinessGrants",
      Indigenous: "IndigenousGrants",
      BlackEntrepreneur: "BlackEntrepreneurshipGrants",
      Environmental: "EnvironmentalGrants",
      SmallBusiness: "SmallBusinessGrants",
      ArtCulture: "ArtCultureGrants",
      StartUp: "StartUpGrants",
      Technology: "TechnologyInnovationGrants",
      ProgramSearch: "ProgramSearch",
      GrantPageDetails: "GrantPageDetails",

      // ListWomen: "WomanOwnedBusinessGrants",
      // ListIndigenous: "IndigenousGrants",
      // ListBlackEntrepreneur: "BlackEntrepreneurshipGrants",
      // ListEnvironmental: "EnvironmentalGrants",
      // ListSmallBusiness: "SmallBusinessGrants",
      // ListArtCulture: "ArtCultureGrants",
      // ListStartUp: "StartUpGrants",
      // ListTechnology: "TechnologyInnovationGrants",
    },
  },
};

export default function App() {
  const [fontsLoaded] = useFonts({
    "Montserrat-Regular": require("@expo-google-fonts/montserrat/Montserrat_400Regular.ttf"),
    "Montserrat-Bold": require("@expo-google-fonts/montserrat/Montserrat_700Bold.ttf"),
    "OpenSans-Regular": require("@expo-google-fonts/open-sans/OpenSans_400Regular.ttf"),
    "OpenSans-Bold": require("@expo-google-fonts/open-sans/OpenSans_700Bold.ttf"),
    "OpenSans-Semibold": require("@expo-google-fonts/open-sans/OpenSans_600SemiBold.ttf"),
  });

  useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  // const [selectedValue, setSelectedValue] = useState("");

  // const handleValueChange = (value) => {
  //   setSelectedValue(value);
  //   if (value) {
  //     navigation.navigate(value);
  //   }
  // };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AboutUs"
          component={AboutUs}
          options={{ headerShown: false, title: "About Us" }}
        />
        <Stack.Screen
          name="Contact"
          component={Contact}
          options={{ headerShown: false, title: "Contact Us" }}
        />
        <Stack.Screen
          name="Women"
          component={Women}
          options={{
            headerShown: false,
            title: "FindGrant - Women-Owned Business Grants",
          }}
        />
        <Stack.Screen
          name="Indigenous"
          component={IndigenousGrants}
          options={{
            headerShown: false,
            title: "FindGrant - Indigenous Grants",
          }}
        />

        <Stack.Screen
          name="ArtCulture"
          component={ArtCulture}
          options={{
            headerShown: false,
            title: "FindGrant - Art and Culture Grants",
          }}
        />
        <Stack.Screen
          name="BlackEntrepreneur"
          component={BlackEntrepreneur}
          options={{
            headerShown: false,
            title: "FindGrant - Black Entrepreneurship Grants",
          }}
        />
        <Stack.Screen
          name="Environmental"
          component={Environmental}
          options={{
            headerShown: false,
            title: "FindGrant - Environmental Business Grants",
          }}
        />
        <Stack.Screen
          name="SmallBusiness"
          component={SmallBusiness}
          options={{
            headerShown: false,
            title: "FindGrant - Small Business Grants",
          }}
        />
        <Stack.Screen
          name="StartUp"
          component={StartUp}
          options={{ headerShown: false, title: "FindGrant - Start Up Grants" }}
        />
        <Stack.Screen
          name="Technology"
          component={Technology}
          options={{
            headerShown: false,
            title: "FindGrant - Technology and Innovation Grants",
          }}
        />
        <Stack.Screen
          name="ProgramSearch"
          component={ProgramSearch}
          options={{
            headerShown: false,
            title: "ProgramSearch",
          }}
        />
        <Stack.Screen
          name="GrantPageDetails"
          component={GrantPageDetails}
          options={{
            headerShown: false,
            title: "GrantPageDetails",
          }}
        />

        <Stack.Screen
          name="FiveMistakes"
          component={FiveMistakes}
          options={{
            headerShown: false,
            title: "FiveMistakes",
          }}
        />

        <Stack.Screen
          name="EducationGrants"
          component={EducationGrants}
          options={{
            headerShown: false,
            title: "EducationGrants",
          }}
        />

        <Stack.Screen
          name="EnvironmentalBusinessGrants"
          component={EnvironmentalBusinessGrants}
          options={{
            headerShown: false,
            title: "EnvironmentalBusinessGrants",
          }}
        />

        <Stack.Screen
          name="StartupGrantsCanada2024"
          component={StartupGrantsCanada2024}
          options={{
            headerShown: false,
            title: "StartupGrantsCanada2024",
          }}
        />

        <Stack.Screen
          name="GrantsVsLoans"
          component={GrantsVsLoans}
          options={{
            headerShown: false,
            title: "GrantsVsLoans",
          }}
        />

        <Stack.Screen
          name="BlackEntrepreneursGrants"
          component={BlackEntrepreneursGrants}
          options={{
            headerShown: false,
            title: "BlackEntrepreneursGrants",
          }}
        />

        <Stack.Screen
          name="HowToFind"
          component={HowToFind}
          options={{
            headerShown: false,
            title: "HowToFind",
          }}
        />

        <Stack.Screen
          name="SmallBusinessGrantsPost"
          component={SmallBusinessGrantsPost}
          options={{
            headerShown: false,
            title: "SmallBusinessGrantsPost",
          }}
        />

        <Stack.Screen
          name="GrantApplicationGuide"
          component={GrantApplicationGuide}
          options={{
            headerShown: false,
            title: "GrantApplicationGuide",
          }}
        />

        <Stack.Screen
          name="TopFundingSources"
          component={TopFundingSources}
          options={{
            headerShown: false,
            title: "TopFundingSources",
          }}
        />

        <Stack.Screen
          name="TopGovernmentGrants"
          component={TopGovernmentGrants}
          options={{
            headerShown: false,
            title: "TopGovernmentGrants",
          }}
        />

        <Stack.Screen
          name="TopIndigenousGrants"
          component={TopIndigenousGrants}
          options={{
            headerShown: false,
            title: "TopIndigenousGrants",
          }}
        />

        <Stack.Screen
          name="TopResources"
          component={TopResources}
          options={{
            headerShown: false,
            title: "TopResources",
          }}
        />

        <Stack.Screen
          name="WomenInBusiness"
          component={WomenInBusiness}
          options={{
            headerShown: false,
            title: "WomenInBusiness",
          }}
        />
      </Stack.Navigator>
      <StatusBar />
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    alignItems: "center",
    justifyContent: "flex-start", // Please use flex-start so Components start from top of page with no random margins.
  },
});
