import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const screenWidth = Dimensions.get('window').width

export default function StartupGrantsCanada2024 () {
  return (
    <>
        <Header/>

        {/* Title and Introduction */}
        <View style={styles.headerSection}>
            <Text style={styles.title}>Essential Start-Up Grants for Canadian Entrepreneurs in 2024</Text>
        </View>

        <ScrollView style={styles.container}
            showsVerticalScrollIndicator={false}  // Hide vertical scrollbar
            showsHorizontalScrollIndicator={false} // Hide horizontal scrollbar
        >
 

            <View style={styles.sectionContainer}>
                <View style={styles.section}>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUp4.webp")}/>
                    <Text style={styles.introText}>
                        Canadian entrepreneurs in 2024 can access a wide range of grants designed to support the launch and growth of their businesses.
                        These funding opportunities help reduce the financial burden of starting a business, providing crucial capital to cover startup costs, expansion efforts, and innovation projects.
                        Here’s an overview of some essential grants available for Canadian startups in 2024.
                    </Text>
                </View>

                {/* Canada Small Business Financing Program (CSBFP) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Canada Small Business Financing Program (CSBFP)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUp1.webp")}/>
                    <Text style={styles.text}>
                    The Canada Small Business Financing Program is a key resource for new entrepreneurs. It helps small businesses secure loans to cover a variety of expenses, including purchasing equipment and property or improving facilities. 
                    While it’s a loan guarantee program, not a direct grant, it allows entrepreneurs to access larger loans from financial institutions, making it easier to secure financing with favorable terms.
                    </Text>
                </View>

                {/* Futurpreneur Canada */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Futurpreneur Canada</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUp5.jpeg")}/>
                    <Text style={styles.text}>
                    For young entrepreneurs, Futurpreneur Canada offers startup loans, mentorship, and resources specifically for those between 18 and 39 years old. 
                    Futurpreneur’s support can be essential for startups needing initial capital to get off the ground. In addition to loans, Futurpreneur provides access to a business mentor for two years to guide young entrepreneurs through the early stages of their business journey.
                    </Text>
                </View>

                {/* Business Scale-Up and Productivity Program (BSPP) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Business Scale-Up and Productivity Program (BSPP)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUp2.webp")}/>
                    <Text style={styles.text}>
                    The Business Scale-Up and Productivity Program is designed for high-growth startups. This federal initiative provides funding to help businesses scale up operations, improve productivity, and commercialize new products or technologies. 
                    The program focuses on companies in key sectors such as clean technology, manufacturing, and digital industries. Grants cover up to 50% of project costs, making it easier for startups to accelerate their growth while reducing financial risks.
                    </Text>
                </View>

                {/* Innovative Solutions Canada (ISC) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Innovative Solutions Canada (ISC)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUpInno9.webp")}/>
                    <Text style={styles.text}>
                    Innovative Solutions Canada (ISC) is a federal program that offers funding to startups developing innovative products or services that address government challenges. 
                    The program is split into two streams: one provides funding for research and development, while the other focuses on helping businesses commercialize their innovations. ISC is a valuable resource for startups in the tech sector, providing both financial support and access to government procurement opportunities.
                    </Text>
                </View>

                {/* Regional Development Agencies (RDAs) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Regional Development Agencies (RDAs)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUpRegion10.webp")}/>
                    <Text style={styles.text}>
                    Canada’s Regional Development Agencies (RDAs) offer various funding programs tailored to local business needs. Each region of the country has its own RDA, such as FedDev Ontario, Western Economic Diversification Canada, and Atlantic Canada Opportunities Agency. 
                    These agencies provide grants and loans to startups and small businesses working to drive regional economic development. Funding can cover a wide range of activities, including business expansion, innovation, and marketing.
                    </Text>
                </View>

                {/* Canada Digital Adoption Program (CDAP) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Canada Digital Adoption Program (CDAP)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUpDigital11.webp")}/>
                    <Text style={styles.text}>
                    The Canada Digital Adoption Program offers funding to help small businesses adopt new digital technologies and enhance their online presence. 
                    For startups looking to improve their e-commerce capabilities, adopt new software, or streamline operations with digital tools, this program provides grants to cover the cost of implementing these technologies.
                    </Text>
                </View>

                {/* Scientific Research and Experimental Development (SR&ED) Tax Incentive */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Scientific Research and Experimental Development (SR&ED) Tax Incentive</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUpSci12.webp")}/>
                    <Text style={styles.text}>
                    The SR&ED program is one of the largest government-backed programs in Canada, offering tax incentives to businesses engaged in research and development. 
                    Startups that are developing new products, technologies, or processes can receive tax credits for eligible R&D expenses. This program supports innovation by offsetting the cost of high-risk projects, making it an essential resource for tech-focused startups.
                    </Text>
                </View>

                {/* Women Entrepreneurship Strategy (WES) */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Women Entrepreneurship Strategy (WES)</Text>
                    <Image style={styles.image} source={require("../../../assets/blogs/StartUps/startUpWomen13.webp")}/>
                    <Text style={styles.text}>
                    The Women Entrepreneurship Strategy is a government initiative aimed at increasing access to financing, mentorship, and skills development for women entrepreneurs. 
                    It includes both loan and grant programs, providing women-led startups with the resources they need to grow their businesses. WES funds projects that promote business growth and innovation, ensuring that women entrepreneurs have equal opportunities to succeed in the Canadian business landscape.
                    </Text>
                </View>

                {/* Conclusion */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Conclusion</Text>
                    <Text style={styles.text}>
                    In 2024, Canadian entrepreneurs have access to a diverse array of grants and funding programs that can help them launch and grow their businesses. 
                    From government-backed initiatives to region-specific grants, these funding sources provide crucial capital, mentorship, and resources to ensure startup success. 
                    Whether you’re in the early stages of building your business or scaling up operations, these grants can help you access the financial support needed to thrive in a competitive market.
                    </Text>
                </View>

                {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/StartUpGrants')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>'s Start-Up Grants section offers tailored information.
                        </Text>
                    </View>
                </View>
            </View>
            <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f9f9f9',

    // padding: 16,
  },
  headerSection: {
    paddingTop: 10,
  },

  sectionContainer:{
    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 230: screenWidth > 1261 ? 800 :  600,    borderRadius: 10,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
