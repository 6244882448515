import React, { useState, useRef, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Dimensions
} from "react-native";

const screenWidth = Dimensions.get("window").width;

const SearchBarForSearchProgram = ({
  title,
  placeholder,
  items,
  style,
  children,
  onChange,
  defaultValue = "",
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [dropdownPositions, setDropdownPositions] = useState({}); // Store positions for each field

  const dropdownRef = useRef();

  const handleSearch = (text) => {
    setInputValue(text);
    const filteredData = items.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredItems(filteredData);
    setIsDropdownVisible(true); // Show dropdown when typing
  };

  const handleItemSelect = (option) => {
    setInputValue(option.label);
    setIsDropdownVisible(false); // Hide dropdown after selection
    //Propagate selected value to parent via onChange
    if (onChange) {
      onChange(option.label);
    }
  };

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Hide dropdown if clicked outside
    }
  };

  // Function to get the layout of the TextInput (to position dropdown accordingly)
  // const handleInputLayout = (event) => {
  //   const { x, y, width, height } = event.nativeEvent.layout;
  //   setDropdownPosition({
  //     top: y + height, // Position the dropdown just below the input
  //     left: x,
  //     width: width,
  //   });
  // };

  // Handle the onFocus event to display the dropdown
  const handleFocus = (fieldName) => {
    setIsDropdownVisible(true);
  };
  

  // Function to get the layout of each TextInput (to position the dropdown accordingly)
  const handleInputLayout = (event, fieldName) => {
    const { x, y, width, height } = event.nativeEvent.layout;
    setDropdownPositions((prevState) => ({
      ...prevState,
      [fieldName]: {
        top: y + height, // Position the dropdown just below the input
        left: x,
        width: width,
      },
    }));
  };


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setInputValue(defaultValue); // Asegurarse de actualizar si defaultValue cambia
  }, [defaultValue]);

  return (
    <View style={styles.outerContainer}>
      <View style={[styles.container, style]} ref={dropdownRef}>
        {/* Search and Select Input */}
        <View style={styles.placeholder}>
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          <TextInput
            value={inputValue}
            onChangeText={handleSearch}
            placeholder={placeholder}
            style={[styles.input, inputValue !== "" && styles.selectedText]}
            // onFocus={() => setIsDropdownVisible(true)} // Show dropdown on focus
            // onLayout={handleInputLayout} // Capture the layout of the input field
            onFocus={() => handleFocus("inputField1")} // Use a unique name for each input field
            onLayout={(event) => handleInputLayout(event, "inputField1")} // Assign each input a unique name
          />
        </View>

        {/* Dropdown list */}
        {isDropdownVisible && dropdownPositions.inputField1  && (
          <View style={[styles.dropdown, 
            { 
              // zIndex: 1000,
              // top: dropdownPosition.top, // Set top position dynamically
              // left: dropdownPosition.left, // Set left position dynamically
              // width: dropdownPosition.width, // Set dropdown width to match input field width
            },
            {
              zIndex: 1000,
              top:screenWidth < 560 ? dropdownPositions.inputField1.top - 100 : dropdownPositions.inputField1.top +5, // Position dropdown based on the input's position
              left:screenWidth < 560 ?  dropdownPositions.inputField1.left + 155 : dropdownPositions.inputField1.left -10, // Align dropdown left to the input
              width:screenWidth < 560 ? dropdownPositions.inputField1.width -50 : dropdownPositions.inputField1.width -10, // Match the input width
            },]}>
            <FlatList
              data={inputValue === "" ? items : filteredItems} //if input is empty, displays all options.
              keyExtractor={(option) => option.id.toString()}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={styles.dropdownItem}
                  onPress={() => handleItemSelect(item)}
                >
                  <Text>{item.label}</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        )}

        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    width: 220,
    height: 70,
  },
  placeholder: {
    paddingLeft: 25,
    paddingTop: 12,
  },
  input: {
    paddingTop: 5,
    fontSize: 13,
    color: "grey",
    outlineStyle: "none", //remove text input border
    fontFamily: "OpenSans-Semibold",
  },
  dropdown: {
    position: "absolute",
    top: screenWidth < 500 ? -50: -50,
    // left: 20,
    width: screenWidth < 500 ? "70%" : "80%",
    maxHeight: screenWidth < 500 ? 190 : 150,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    fontFamily: "OpenSans-Semibold",
    left:
      screenWidth < 500
      ? 190
      : screenWidth < 950
        ? -175
        : screenWidth < 1261
        ? -175
        : screenWidth < 1080
        ? 40
        : 40,
  },
  dropdownItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  selectedText: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "OpenSans-Semibold",
  },
  outerContainer: {
  },
});

export default SearchBarForSearchProgram;
