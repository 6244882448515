import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ImageBackground,
  Pressable,
  Animated,
  Dimensions,
  useWindowDimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";

//import components
import DropDown from "./SearchBarComponents/DropdownComponent";
import SearchButton from "./SearchBarComponents/SearchButton";
import AllGrantButton from "./SearchBarComponents/AllGrantCheck";
import FadedCircle from "./SearchBarComponents/FadeCircle";
import { useNavigation } from "@react-navigation/native";

const screenWidth = Dimensions.get("window").width;

const background = require("../../assets/background.png");
const SearchBar = () => {
  // State to save the options of each dropdown from the API
  const [dropdownData, setDropdownData] = useState({
    location: [],
    community: [],
    industry: [],
    grants: [],
  });

  // Status for user selection
  const [selectedValues, setSelectedValues] = useState({
    location: "",
    community: "",
    industry: "",
    grants: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useNavigation();
  const { width } = useWindowDimensions(); // Get the screen width

  // Function to load questions from the API
  const fetchQuestions = async () => {
    try {
      const response = await fetch(
        "https://api.findgrant.ca/webpage/load-questions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      // Map the questions and save the options to the state
      const mappedData = data.reduce((acc, question) => {
        if (question.question === "My location is...") {
          acc.location = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am a...") {
          acc.community = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "My industry is...") {
          acc.industry = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am looking for...") {
          acc.grants = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        }
        return acc;
      }, {});

      setDropdownData(mappedData); // Save options in state
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  // Call the API when the component is mounted
  React.useEffect(() => {
    fetchQuestions();
  }, []);

  // Function to handle selection in dropdowns
  const handleSelectionChange = (type, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  // Function to navigate to ProgramSearch with selected data
  const [query, setQuery] = useState('');
  const [body, setBody] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    console.log("Navigating with values:", selectedValues); // verify
    //navigation.navigate("ProgramSearch", { selectedValues }); // Navigation and data passage
    const { location, community, industry, grants } = selectedValues;
    if (!location && !community && !industry && !grants) {
      setErrorMessage("You must select at least one attribute.");
    } else {
      setErrorMessage(""); // Limpia el mensaje de error
      navigation.navigate("ProgramSearch", { selectedValues });
    }
  };


  return (
    <View style={styles.container_main}>
      {width < 950 ? (
        <View style={styles.container}>
          {/* <View style={styles.circleWrapper}>
            <FadedCircle radius={600} fadeWidth={20} color="#F5BF4C" style={{position: 'absolute', right: -550, top: -750}} />
            <FadedCircle radius={300} fadeWidth={0} color="#F5BF4C"  style={{position: 'absolute', left: -200, top: -20}} />
          </View> */}
          <View style={{ marginTop: -22 }}>
            <Text style={styles.LargeFont}>Your Dream Begins here</Text>
            <Text style={styles.SmallFont}>
              Start by finding your suitable grants in FindGrant
            </Text>
          </View>

          <View style={[styles.searchContainer, { zIndex: 200 }]}>
            {/* <DropDown/> */}
            <View style={[styles.dropdown, { zIndex: 500 }]}>
              <DropDown
                style={[styles.dropdownMenu, { borderRadius: 35 }]}
                title="Location"
                placeholder="Search Location"
                items={dropdownData.location}
                onChange={(value) => handleSelectionChange("location", value)}
              ></DropDown>

              <DropDown
                title="Community"
                placeholder="Select your community group"
                items={dropdownData.community}
                onChange={(value) => handleSelectionChange("community", value)}
              >
                {/* <View style={styles.halfLeftBorder} /> */}
              </DropDown>

              <DropDown
                title="Industry"
                placeholder="Select your industry"
                items={dropdownData.industry}
                onChange={(value) => handleSelectionChange("industry", value)}
              >
                {/* <View style={styles.halfLeftBorder} /> */}
              </DropDown>

              <DropDown
                style={{ borderRadius: 35 }}
                title="Kind of Grant"
                placeholder={'kind of grant you are looking for'}
                items={dropdownData.grants}
                onChange={(value) => handleSelectionChange("grants", value)}
              >
                {/* <View style={styles.halfLeftBorder} />     */}
              </DropDown>
            </View>

            {/* All Grant Button */}
            <AllGrantButton />
          </View>
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}
          <SearchButton onPress= {handleSearch}
           />
        </View>
      ) : width < 1281 ? (
        <View style={styles.container}>
          <View style={styles.circleWrapper}>
            <FadedCircle
              radius={600}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", right: -660, top: -750 }}
            />
            <FadedCircle
              radius={500}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", left: -550, top: -0 }}
            />
          </View>
          <View style={{ marginTop: 22, zIndex: 10 }}>
            <Text style={styles.LargeFont}>Your Dream Begins here</Text>
            <Text style={styles.SmallFont}>
              Start by finding your suitable grants in FindGrant
            </Text>
          </View>

          <View style={[styles.searchContainer, { zIndex: 200 }]}>
            {/* <DropDown/> */}
            <View style={[styles.dropdown, { zIndex: 500 }]}>
              <DropDown
                style={[styles.dropdownMenu, { borderRadius: 35 }]}
                title="Location"
                placeholder="Select your current location"
                items={dropdownData.location}
                onChange={(value) => handleSelectionChange("location", value)}
              ></DropDown>

              <DropDown
                style={{ borderRadius: 35 }}
                title="Community"
                placeholder="Select your community group"
                items={dropdownData.community}
                onChange={(value) => handleSelectionChange("community", value)}
              >
                {/* <View style={styles.halfLeftBorder} /> */}
              </DropDown>

              {/* <DropDown
              title="Industry"
              placeholder="Select your industry"
              items={selection.industry}
            >
              <View style={styles.halfLeftBorder} />
            </DropDown> */}

              {/* <DropDown
              style={{ borderRadius: 35 }}
              title="Kind of Grant"
              placeholder="kind of grant you are looking for"
              items={selection.grants}
            >
              <View style={styles.halfLeftBorder} />    
            </DropDown> */}
            </View>

            <View style={[styles.dropdown, { zIndex: 500 }]}>
              {/* <DropDown
              style={[styles.dropdownMenu, { borderRadius: 35 }]}
              title="Location"
              placeholder="Select your current location"
              items={selection.location}
            ></DropDown> */}

              {/* <DropDown
              title="Community"
              placeholder="Select your community group"
              items={selection.community}
            >
              <View style={styles.halfLeftBorder} />
            </DropDown> */}

              <DropDown
                style={[styles.dropdownMenu, { borderRadius: 35 }]}
                title="Industry"
                placeholder="Select your industry"
                items={dropdownData.industry}
                onChange={(value) => handleSelectionChange("industry", value)}
              >
                {/* <View style={styles.halfLeftBorder} /> */}
              </DropDown>

              <DropDown
                style={{ borderRadius: 35 }}
                title="Kind of Grant"
                placeholder="kind of grant you are looking for"
                items={dropdownData.grants}
                onChange={(value) => handleSelectionChange("grants", value)}
              >
                {/* <View style={styles.halfLeftBorder} />     */}
              </DropDown>
            </View>

            {/* All Grant Button */}
            <AllGrantButton />
          </View>
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}
          <SearchButton onPress={handleSearch}/>
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.circleWrapper}>
            <FadedCircle
              radius={600}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", right: -550, top: -750 }}
            />
            <FadedCircle
              radius={500}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", left: -450, top: -30 }}
            />
          </View>
          <View style={{ marginTop: 22 }}>
            <Text style={styles.LargeFont}>Your Dream Begins here</Text>
            <Text style={styles.SmallFont}>
              Start by finding your suitable grants in FindGrant
            </Text>
          </View>

          <View style={[styles.searchContainer, { zIndex: 200 }]}>
            {/* <DropDown/> */}
            <View style={[styles.dropdown, { zIndex: 500 }]}>
              <DropDown
                style={[styles.dropdownMenu, { borderRadius: 35 }]}
                title="Location"
                placeholder="Select your current location"
                items={dropdownData.location}
                onChange={(value) => handleSelectionChange("location", value)}
              ></DropDown>

              <DropDown
                title="Community"
                placeholder="Select your community group"
                items={dropdownData.community}
                onChange={(value) => handleSelectionChange("community", value)}
              >
                <View style={styles.halfLeftBorder} />
              </DropDown>

              <DropDown
                title="Industry"
                placeholder="Select your industry"
                items={dropdownData.industry}
                onChange={(value) => handleSelectionChange("industry", value)}
              >
                <View style={styles.halfLeftBorder} />
              </DropDown>

              <DropDown
                style={{ borderRadius: 35 }}
                title="Kind of Grant"
                placeholder="kind of grant you are looking for"
                items={dropdownData.grants}
                onChange={(value) => handleSelectionChange("grants", value)}
              >
                <View style={styles.halfLeftBorder} />
              </DropDown>
            </View>

            {/* All Grant Button */}
            <AllGrantButton />
          </View>
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}
          <SearchButton onPress={handleSearch} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container_main: {
    // flex: 1,
    display: "flex",
    marginTop:
      screenWidth < 580
        ? 50
        : screenWidth < 881
        ? 50
        : screenWidth < 1000
        ? 30
        : screenWidth < 1080
        ? 30
        : screenWidth < 1343
        ? 30
        : 40,
    marginBottom: 100,
    alignItems: "center",
    width: "90%",
    minHeight: 300,
    borderRadius: 20,
    backgroundColor: "white",
    // backgroundColor: "red",
    // opacity: "90%",
  },
  container: {
    // flex: 1,
    display: "flex",
    // marginTop: screenWidth < 580  ? 50 : screenWidth < 881  ? 50 : screenWidth < 1000  ? 30 : screenWidth < 1080  ? 30 : screenWidth < 1343  ? 30 : 40,
    // marginBottom: 100,
    paddingTop:
      screenWidth < 580
        ? 60
        : screenWidth < 881
        ? 70
        : screenWidth < 1000
        ? 30
        : screenWidth < 1080
        ? 30
        : screenWidth < 1343
        ? 30
        : 40,
    paddingBottom:
      screenWidth < 580
        ? 20
        : screenWidth < 881
        ? 30
        : screenWidth < 1000
        ? 30
        : screenWidth < 1080
        ? 30
        : screenWidth < 1343
        ? 30
        : 40,
    alignItems: "center",
    width: "100%",
    minHeight: 300,
    borderRadius: 20,
    // paddingRight: 40,
    // paddingLeft: 40,
    backgroundColor: "#316EE5",
    opacity: "90%",
  },
  circleWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    // marginBottom:
    //   screenWidth < 881
    //     ? null
    //     : screenWidth < 900
    //     ? -20
    //     : screenWidth < 1261
    //     ? -20
    //     : null,
    zIndex: -1,
    borderRadius: 20,
  },
  LargeFont: {
    fontSize:
      screenWidth < 881
        ? "3vh"
        : screenWidth < 1261
        ? 34
        : screenWidth < 1080
        ? 34
        : 34,
    color: "white",
    marginBottom: 10,
    textAlign: "center",
  },
  SmallFont: {
    fontSize: 14,
    textAlign: "center",
    color: "white",
  },

  searchContainer: {
    // display: 'flex',
    gap: 20,
    flexDirection:
      screenWidth < 881
        ? "column"
        : screenWidth < 950
        ? "column"
        : screenWidth < 1080
        ? "row"
        : "row",
    // justifyContent: "space-around",
    justifyContent: "center",
    alignItems: "center",
    // alignContent: "flex-start",
    marginTop: 30,
    // backgroundColor: "red",
    width:
      screenWidth < 580
        ? "75%"
        : screenWidth < 881
        ? "81%"
        : screenWidth < 1000
        ? "81%"
        : screenWidth < 1080
        ? "81%"
        : screenWidth < 1343
        ? "80%"
        : "60%",
    marginVertical: 5,
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 35,
    flexDirection:
      screenWidth < 881
        ? "column"
        : screenWidth < 1281
        ? "column"
        : screenWidth < 1080
        ? "row"
        : "row",
    textAlign: "left",
    width:
      screenWidth < 580
        ? "100%"
        : screenWidth < 881
        ? "81%"
        : screenWidth < 1000
        ? "40%"
        : screenWidth < 1080
        ? "40%"
        : screenWidth < 1343
        ? "40%"
        : "",
  },
  dropdownMenu: {
    // width: "100%",
  },
  halfLeftBorder: {
    position: "absolute",
    left: 0,
    top: "17.5%",
    height: "65%", // Half of the height
    width: 1, // Width of the border
    backgroundColor: "#ccc", // Border color
  },
  errorText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat-Bold",
    marginTop: 20,
    fontSize: 20,
    textAlign: "center",
  },
});

function ConvertToProperty(data) {
  return data.split(";").map((data, index) => ({
    id: index + 1,
    label: data.trim(),
  }));
}

export default SearchBar;
