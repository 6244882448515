import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Pressable,
  Dimensions,
} from "react-native";
import React, {useState, useEffect} from "react";
import SaveIcon from "./SaveIcon";
import { useNavigation } from "@react-navigation/native";

const screenWidth = Dimensions.get("window").width;

const ListGrants = ({ data }) => {
  const navigation = useNavigation();
  const headers = ["Funding Program", "Funding Source", "Description"];

  // State to track whether the icon is pressed or not
  const [isPressed, setIsPressed] = useState(false);

  // State to track which icon is pressed (using an object or array)
  const [pressedState, setPressedState] = useState({});

  // Toggle icon color for a specific row
  const handlePress = (rowIndex) => {
    setPressedState((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex], // Toggle the pressed state for the specific row
    }));
  };

  return (
    // <ScrollView horizontal contentContainerStyle={{ paddingHorizontal: 10 }}>
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>List of Grants</Text>
        </View>
        <View style={styles.table}>
          {/* Encapsulating the header */}
          <View style={styles.headerContainer}>
            <View style={[styles.row, styles.headerRow]}>
              {headers.map((header, index) => (
                <View key={index} style={[styles.cell, styles.headerCell]}>
                  <Text style={[styles.text, styles.headerText]}>{header}</Text>
                </View>
              ))}
              <View style={[styles.headerIconCell]}>
                {/* <SaveIcon width={24} height={27} color="#011F6F" /> */}
              </View>
            </View>
          </View>

          {/* Encapsulating the Body */}
          {screenWidth < 700 ? (
            <View style={styles.bodyContainer}>
            {data.map((row, rowIndex) => {
              if (typeof row === "object" && !Array.isArray(row)) {
                // Render each object as a row
                return (
                  <View
                      key={rowIndex}
                      style={[
                        styles.row,
                        rowIndex === data.length - 1 && { borderBottomWidth: 0 },
                      ]}
                    >
                      {/* Program Name */}
                      <View style={styles.cell}>
                        <View style={styles.nameCell} >
                        <Pressable
                          onPress={() =>
                            navigation.navigate("GrantPageDetails", {
                              //short_url: `${row.short_url}-${row.slug}`,
                              short_url: `${row.short_url}`,
                              slug: `${row.slug}`,
                            })                          
                          }
                          style= {styles.programName}
                        >
                          <Text style={[styles.text, { color: "#011F6F" }]}>
                            <strong>{row.program_name || "N/A"}</strong>
                          </Text>
                        </Pressable>
                        <Pressable 
                          key={rowIndex}
                          onPress={() => handlePress(rowIndex)}
                        >
                          <View style={styles.iconCell1}>
                            <SaveIcon width={24} height={27} color="#011F6F"/>
                            {/* <SaveIcon 
                              width={24} 
                              height={27} 
                              color={pressedState[rowIndex] ? 'green' : '#011F6F'} // Change color based on pressedState for that row
                              style={{ fill: pressedState[rowIndex] ? 'green' : 'none' }} // Use style to control the fill
                            /> */}
                          </View>
                        </Pressable>
                        </View>
                      </View>
                      {/* Program Type */}
                      <View style={styles.cell}>
                        <Text style={styles.text}>
                          {row.program_type || "N/A"}
                        </Text>
                      </View>
                      {/* Description */}
                      <View style={styles.cell}>
                        <Text style={styles.text} numberOfLines={2} >
                          {row.program_description || "N/A"}
                        </Text>
                      </View>
                  </View>
                );
              } else {
                console.error(
                  `Row at index ${rowIndex} is not an object:`,
                  row
                );
                return null;
              }
            })}
            </View>
          ) : (
          <>
          {/* Encapsulating the Body */}
            <View style={styles.bodyContainer}>
              {data.map((row, rowIndex) => {
                if (typeof row === "object" && !Array.isArray(row)) {
                  // Render each object as a row
                  return (
                    <View
                      key={rowIndex}
                      style={[
                        styles.row,
                        rowIndex === data.length - 1 && { borderBottomWidth: 0 },
                      ]}
                    >
                      {/* Program Name */}
                      <View style={styles.cell}>
                        <Pressable
                          onPress={() =>
                            navigation.navigate("GrantPageDetails", {
                              //short_url: `${row.short_url}-${row.slug}`,
                              short_url: `${row.short_url}`,
                              slug: `${row.slug}`,
                            })                          
                          }
                        >
                          <Text style={[styles.text, { color: "#011F6F" }]}>
                            <strong>{row.program_name || "N/A"}</strong>
                          </Text>
                        </Pressable>
                      </View>
                      {/* Program Type */}
                      <View style={styles.cell}>
                        <Text style={styles.text}>
                          {row.program_type || "N/A"}
                        </Text>
                      </View>
                      {/* Description */}
                      <View style={styles.cell}>
                        <Text style={styles.text} numberOfLines={2} >
                          {row.program_description || "N/A"}
                        </Text>
                      </View>
                      {/* Save Icon */}
                      <View style={styles.iconCell}>
                        <Pressable 
                          key={rowIndex}
                          onPress={() => handlePress(rowIndex)}
                        >
                          <View style={styles.iconCell1}>
                            <SaveIcon width={24} height={27} color="#011F6F"/>
                            {/* <SaveIcon 
                              width={24} 
                              height={27} 
                              color={pressedState[rowIndex] ? 'green' : '#011F6F'} // Change color based on pressedState for that row
                              style={{ fill: pressedState[rowIndex] ? 'green' : 'none' }} // Use style to control the fill
                            /> */}
                          </View>
                        </Pressable>
                      </View>
                    </View>
                  );
                } else {
                  console.error(
                    `Row at index ${rowIndex} is not an object:`,
                    row
                  );
                  return null;
                }
              })}
            </View>
          </>
          )}
        </View>
      </View>
    // </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",    
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "left",
    marginBottom: 55,
    marginLeft: "auto",
    marginRight: "auto", 
    paddingHorizontal:
    screenWidth < 700
      ? "3vw"
      : screenWidth < 821
      ? "3vw"
      : screenWidth < 1057
      ? 30
      : screenWidth < 1280
      ? 33
      : 45,
    flexWrap: "wrap", // Allow boxes to wrap to the next line
    width: screenWidth,  
    // backgroundColor: "red",
  },
  title: {
    fontFamily: "OpenSans-Bold",    
    fontSize: screenWidth > 400 ? 24 : 24, 
    color: "#011F6F",
    marginBottom: 10,    
    paddingLeft: 20,    
  },
  table: {
    padding: 20,
    backgroundColor: "#E2E7F5",
    borderRadius: 60,
    width: "100%",
    flexDirection: 'column', // Default to column for smaller screens
  },
  headerContainer: {
  },
  bodyContainer: {
    borderRadius: 40,
    overflow: "hidden",
  },
  headerRow: {
    backgroundColor: "#E2E7F5",
    borderBottomWidth: 0,
    flexDirection: screenWidth < 700 ? "column" : "row",
    justifyContent: 'flex-start',
    flexWrap: 'wrap', // Wrap content for small screens
    // backgroundColor: "green",
  },
  row: {
    flexDirection: screenWidth < 700 ? "column" : "row",
    backgroundColor: "#fff",
    padding: 10,
    paddingBottom: screenWidth < 580 ? 20 : 10,
    borderBottomWidth: 1,
    borderBottomColor: "#EBEBEB",
    flexWrap: 'wrap', // Allow wrapping for smaller screens
  },
  cell: {
    padding: screenWidth < 700 ? 1 : 10, //Spacing between cells
    paddingTop: screenWidth < 700 ? 15 : 10,    
    paddingLeft: screenWidth < 700 ? null : 40,
    paddingHorizontal: screenWidth < 700 ? 20 : null,
    minWidth: 100,
    alignItems: "flex-start",
    flexWrap: 'wrap', // Allow wrapping for smaller screens
    flex: screenWidth < 700 ? null : 1,
    textAlign: "Left",
    paddingHorizontal: screenWidth < 700 ? 15 : null,
    justifyContent: null, 
  },
  nameCell:{
    width: "100%",
    flexWrap: "wrap", //
    flexDirection: "row",
    justifyContent: "space-between",
  },
  programName:{
    minWidth: 100,
    width: "83%",
    flexWrap: 'wrap', 
    alignItems: "flex-start",
  },
  iconCell: {
    paddingTop: 15,
    paddingRight: 40,
    paddingBottom: screenWidth < 700 ? 0 : 30,    
    paddingLeft: screenWidth < 700 ? 18 : 30,
  },
  iconCell1: {
    justifyContent: "center",
  },
  headerCell: {
    alignItems: "flex-start",
    justifyContent: "center",
    minWidth: 100,
    padding: 10,
    paddingLeft: 40,
    paddingRight: screenWidth < 700 ? 50 : null,
    flex: 1,
    // backgroundColor: "brown",
  },
  text: {
    fontSize: screenWidth < 700 ? 16 : 16,
    color: "#000000",
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    textAlign: "Left",
    flexWrap: "wrap",
  },
  headerText: {
    color: "#011F6F",
    fontFamily: "OpenSans-Regular",
    fontWeight: "600",
    fontSize: 18,
    // lineHeight: 27.24,
    textAlign: "left",
    alignSelf: screenWidth < 700 ? "center" : null,
    // backgroundColor: "blue",
  },
});

export default ListGrants;
